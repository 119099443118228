
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import StripePayment from './stripe-payments';
import ConfirmPayment from './stripe-payments/success-payment';
import ErrorPayment from './stripe-payments/error-payment';

const router = createBrowserRouter([
  {
    path: "/",
    element: <StripePayment />,
  },
  {
    path: "completed",
    element: <ConfirmPayment />,
  },
  {
    path: 'payment-error',
    element: <ErrorPayment />
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
