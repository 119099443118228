import { useEffect, useState, useCallback } from "react";
import axios from "axios";

export const useCreatePaymentIntention = ({ url, customerPayload }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const getPaymentIntent = useCallback(async(payload) => {
    const createUserUrl = `${url}/customers`;
    // const createSuscriptionUrl = `${url}/subscriptions`;
    const createPaymentIntentUrl = `${url}/paymentIntent`;

    let customerResult;
    try{
      customerResult = await axios.post(createUserUrl, payload.customer);
    } catch(error) {
      setError('Error at create customer');
      setLoaded(true);
      return;
    }

    // const suscriptionPayload = {
    //   customer: customerResult.data.id,
    //   items: [
    //     {
    //       billing_thresholds: {
    //         usage_gte: 1
    //       },
    //       price_data: {
    //         currency: payload.payment.currency,
    //         unit_amount: payload.payment.item_price,
    //         product: 'prod_Mt83uPfx3iAiEn',
    //         recurring: {
    //           interval_count: 1,
    //           interval: "year"
    //         }
    //       },
    //       tax_rates: [],
    //     },
    //   ],
    //   collection_method: 'charge_automatically',
    //   payment_behavior: 'default_incomplete',
    //   payment_settings: {
    //     save_default_payment_method: 'on_subscription'
    //   }
    // }

    // try {
    //   const paymentResult = await axios.post(createSuscriptionUrl, suscriptionPayload);
      
    // } catch (error) {
    //   setError('Error on load payment intent');
    //   setLoaded(true);
    // }

    try {
      const paymentIntentPayload = {
        amount: payload.payment.item_price,
        currency: 'USD',
        customer: customerResult.data.id
      }
      const paymentIntent = await axios.post(createPaymentIntentUrl, paymentIntentPayload);

      setData({
        paymentIntent: paymentIntent.data.client_secret,
        clientId: customerResult.data.id
      })
      setLoaded(true);
    } catch (error) {
      
    }
  }, [url]);

  useEffect(() => {
    if (!loaded) getPaymentIntent(customerPayload);
  }, [customerPayload, getPaymentIntent, loaded])
  

  

  return { data, loaded, error }
}