import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { NumericFormat } from 'react-number-format';
import { useState } from 'react';

import { ErrorPayment } from './error-payment';
import { SuccessPayment } from './success-payment';

import logo from '../assets/img/logo.svg';
import secure from '../assets/img/secure.svg';

const CheckoutForm = ({ price, period = 'Anual' }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const goBack = () => {
    setError(null);
    setSuccess(false);
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setLoading(false);
      setError('Stripe error loading')
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        // return_url: "http://localhost:3000/completed",
      },
    });

    if (result.error) {
      setLoading(false);
      if (result?.error?.decline_code) setError(result.error.decline_code);

      // navigate(`/payment-error?clientsecret=${clientsecret}&error=${result.error.decline_code}`)
      // Show error to your customer (for example, payment details incomplete)
    } else {
      setLoading(false);
      setError(null);
      setSuccess(true);
      console.log('result', result);
      // TODO: send result to api and close the windows 
    }
  };

  return (
    <div className='m-auto w-full max-w-[76s0px] p-6 relative bg-[#FAFAFA]'>
      <form onSubmit={handleSubmit}>
      <div className=' w-full max-w-5xl m-auto'>
          <div className=' w-full max-w-[724px] m-auto'>
            <div className='mb-3 p-6'>
              <h3>Pago con tarjeta</h3>
              <p>
                Ingresa la información de la <b>tarjeta</b> con la que se realizará el pago. 
              </p>
            </div>
            <div className='w-full flex justify-between'>
              <div className='w-[342px]'>
                <div>
                  <PaymentElement />
                </div>
                <div className='mt-[96px]'>
                  <div className=' flex gap-6 -mx-4'>
                    <img src={secure} alt='secure' />
                    <div className=' bg-[#9BD381]/50 px-4 py-2 w-[320px] rounded-lg text-xs'>
                      Te encuentras en una sección de pago protegida para garantizar la seguridad de tu información
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-between'>
                <div className=' w-[260px] h-[257px] border border-[#E1E4F4] rounded-lg py-5 flex flex-col justify-start'>
                  <div className='flex justify-between px-4 text-[#7B89B0] text-base pb-3 flex-initial'>
                    Resumen de pago
                    <img src={logo} alt='asistenli logo' />
                  </div>
                  <hr className='w-[240px]  bg-[#E1E4F4]'/>
                  <div className='flex justify-center gap-4 flex-col flex-auto'>
                    <div className=' px-4'>
                      <div className=' text-[#7B89B0] text-sm leading-5'>
                        Monto a pagar
                      </div>
                      <div className='text-[#200944] text-base font-bold'>
                        <NumericFormat value={price} allowLeadingZeros thousandSeparator="," prefix={'$'} decimalScale={2} fixedDecimalScale/>
                      </div>
                    </div>
                    <hr className='w-[240px] bg-[#E1E4F4] self-center'/>
                    <div className=' px-4'>
                      <div className=' text-[#7B89B0] text-sm leading-5'>
                        Periodicidad 
                      </div>
                      <div className='text-[##200944] text-base  font-bold'>
                        {period}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-end'>
                  <button className='bg-[#FF2E65] text-[#FFFFFF] text-base leading-6 rounded-3xl flex justify-center items-center px-6 py-2' disabled={!stripe} >
                    Finalizar pago
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className='absolute top-0 left-0 w-full h-full bg-[#FAFAFA]' style={{ display: loading || error || success ? 'block': 'none' }}>
        { loading && (<div className='max-lg flex justify-center items-center h-[604px]'> Loading... </div>)}
        { !loading && error && <ErrorPayment errorMessage={error} goBack={goBack} /> } 
        { !loading && success && <SuccessPayment /> } 
      </div>
    </div>
  );
};

export default CheckoutForm;