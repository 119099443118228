import image from '../assets/img/Payment-failed.svg';

export const ErrorPayment = ({ errorMessage, goBack }) => {

  const solutionsOptions = [
    <p>Corroborar <b>con tu banco</b> que el pago se pueda realizar.</p>,
    <p>Revisar que <b>la información de tu tarjeta</b> sea la correcta.</p>,
    <p>Que <b>tu conexión</b> a internet sea estable.</p>,
    <p><b>Cambia</b> tu método de pago.</p>
  ];

  return <div className='m-auto w-full max-w-[772px] p-6 h-full flex flex-col justify-center'>
    <div className='flex justify-center items-center mb-11'>
      <img src={image} alt='fail p[ayment' />
    </div>
    <div>
      <h1 className='text-center font-bold text-xl text-[##200944] leading-7 mb-4'>Error en el pago</h1>
      <div className='text-[#200944] text-base leading-6 text-center'>
        El pago no pudo ser procesado por <b>{errorMessage || 'error'}</b>, te recomendamos:
      </div>
      <div className='max-w-[490px] flex justify-center items-center mx-auto my-3'>
        <div>
          <ul class="list-disc text-[#200944] ">
            {solutionsOptions.map((item) => <li>{item}</li>)}
          </ul>
        </div>
      </div>
      <div className='text-[#7B89B0] text-center mt-6'>
        Vuelve a capturar la información de tu tarjeta. 
      </div>
      <div className='flex justify-center my-4'>
        <button
          className='text-base text-[#200944] m-auto rounded-full border border-[#200944] py-2 px-6 leading-6'
          onClick={goBack}
        >
          {'<'}Volver
        </button>
      </div>
      <div className='text-[#7B89B0] text-center mt-6'>
        O puedes <b className='text-[#200944]'>cambiar tu método de pago.</b>
      </div>
    </div>
  </div>
}

export default ErrorPayment;