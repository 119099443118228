import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import CheckoutForm from './checkout-form';
import { getQueryStringParams } from '../utils';
import { useCreatePaymentIntention } from './use-create-payment-intention';


const { REACT_APP_STRIPE_PUBLIC_KEY, REACT_APP_API_URL } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const StripePayment = () => {
  // TODO: add api call to create payment intent 
  const { search } = useLocation();

  const queryParams = getQueryStringParams(search);

  const customerPayload = useMemo(() => ({
    customer: {
      email: queryParams.email,
      name: queryParams.name,
      description: queryParams.description,
      address: {
        country: queryParams.adreess_country,
        city: queryParams.address_city,
        state: queryParams.address_state,
        line1: queryParams.address_line1,
        line2: queryParams.address_line2,
        postal_code: queryParams.address_postal_code,
      },
      // shipping: {
      //   country: queryParams.shipping_country,
      //   city: queryParams.shipping_city,
      //   state: queryParams.shipping_state,
      //   line1: queryParams.shipping_line1,
      //   line2: queryParams.shipping_line2,
      //   postalCode: queryParams.shipping_postal_code
      // }
    },
    payment: {
      item_price: queryParams.price,
      interval: queryParams.interval || 'year',
      currency: queryParams?.currency || 'USD'
    }
  }), [queryParams]);

  const { data, loaded, error } = useCreatePaymentIntention({ url: REACT_APP_API_URL, customerPayload });

  if (!loaded) return <div>Loading...</div>

  console.log('data', data);

  if (error) return <div>Error on load stripe</div>;

  if (!data?.paymentIntent) return <div>Error on get stripe id</div>;

  const options = {
    // get from server (payment intent)
    clientSecret: data.paymentIntent,
  }

  return (<Elements stripe={stripePromise} options={options}>
    <CheckoutForm price={queryParams.price} />
  </Elements>);
        
}

export default StripePayment;