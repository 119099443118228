import image from '../assets/img/Payment-success.svg';

export const SuccessPayment = () => {

  return <div className='m-auto w-full max-w-[772px] p-6 h-full flex items-center flex-col justify-center'>
    <div className='flex justify-center items-center mb-11'>
      <img src={image} alt='fail p[ayment' />
    </div>
    <div>
      <h1 className='text-center font-bold text-xl text-[##200944] leading-7 mb-4'>¡Pago completado!</h1>
      <div className='text-[#200944] text-base leading-6 text-center'>
        <p>
          Tu pago fue realizado con <b>éxito</b>, en breve recibirás un correo con <b>tu comprobante</b> de pago y los pasos siguientes.
        </p>

        <p className='font-medium mt-4'>¡Bienvenido a la familia asistensi!</p>
      </div>
      <div className=' mt-16'>
        <p className='text-[#7B89B0] text-base text-center'>Has concluido el proceso, cierra la ventana en tu navegador o selecciona Volver al inicio</p>
      </div>
    </div>
  </div>
}

export default SuccessPayment;